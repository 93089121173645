export const API = "/api";
export const EUR = new Intl.NumberFormat("de-DE", {
  currency: "EUR",
  style: "currency",
});
export const EUR_4DIGITS = new Intl.NumberFormat("de-DE", {
  currency: "EUR",
  style: "currency",
  minimumIntegerDigits: 2,
  minimumFractionDigits: 2,
});
export const EXTRA_PRICES = [
  [0.5, 1, 2],
  [1, 1.5, 3],
  [0, 0, 0],
  [1, 2, 3],
  [0, 0, 0],
];

export function displayVariant(variant) {
  return variant.toLowerCase().includes("ohne") ? variant : `Mit ${variant}`;
}

export const PAYPAL_CLIENT_ID = "Abh3GfuUYX8ZNxTNkQEQoynK_X7eHmk2Ddw3LqEYzJh0bli1u7iKrcm0fzt85GlRWTUtIzrYNr6FGjrT";